<template>
  <b-card>
    <div>
      <!-- input search -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-button
              variant="primary"
              :to="{name:'Client-register-payer'}"
              block
            >
              Registrar pagador
            </b-button>
          </div>
        </b-form-group>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <div
          slot="emptystate"
          class="text-center my-5"
        >
          <h3
            v-if="payers.length>0"
          >
            Su búsqueda no coincide
          </h3>
          <b-overlay
            id="overlay-background"
            :show="show"
            rounded="lg"
            variant="bg-light"
            opacity="1.00"
            no-wrap
            no-fade
            class="mt-5"
          />
          <h3
            v-if="!show && payers.length==0"
          >
            Aún no han registrado pagadores
          </h3>
        </div>
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: fullName -->
          <div
            v-if="props.column.field === 'fullName'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.fullName }}</span>
          </div>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ getStatus(props.row.status) }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-button
                v-if="props.row.status === 'approved'"
                variant="primary"
                :to="{ name:'Client-credit-evaluation', params:{ id: props.row._id } }"
                block
              >
                Ampliar línea
              </b-button>
              <span
                v-else
              >
                -
              </span>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Mostrando grupos de
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap "> de {{ props.total }} registro(s) </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </b-card>
</template>

<script>
/* eslint-disable global-require */
import {
  BBadge, BPagination, BFormGroup, BFormSelect, BButton, BCard, BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'

export default {
  components: {
    VueGoodTable,
    BOverlay,
    BBadge,
    BPagination,
    BFormGroup,
    BFormSelect,
    BButton,
    BCard,
  },
  data() {
    return {
      show: true,
      userRuc: JSON.parse(localStorage.getItem('userData')).ruc,
      userEmail: JSON.parse(localStorage.getItem('userData')).email,
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'R.U.C',
          field: 'ruc',
        },
        {
          label: 'Razón social',
          field: 'businessName',
        },
        {
          label: 'Estatus',
          field: 'status',
        },
        {
          label: 'Línea con el pagador',
          field: 'billingAmount',
          tdClass: 'text-right',
          type: 'number',
        },
        {
          label: 'Línea disponible',
          field: 'disponibleAmount',
          tdClass: 'text-right',
          type: 'number',
          formatFn: this.moneyFormat,
        },
        {
          label: 'Ampliar línea',
          field: 'action',
        },
      ],
      rows: [],
      payers: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        current      : 'light-primary',
        approved : 'light-success',
        rejected     : 'light-danger',
        notApproved     : 'light-warning',
        applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    useJwt.getMyPayers({
      ruc: this.userRuc,
      email: this.userEmail,
    }).then(res => {
      res.data.payers.forEach(payer => {
        // eslint-disable-next-line
        payer.billingAmount = !Number.isNaN(Number.parseFloat(payer.billingAmount)) ? (payer.currency === 'Soles' ? 'S/' : '$').concat(' ', Number.parseFloat(payer.billingAmount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) : '-'
      })

      this.rows = res.data.payers ? res.data.payers : []
      this.payers = this.rows.length > 0 ? this.rows : []
      this.show = false
    })
  },
  methods: {
    moneyFormat(money) {
      return !Number.isNaN(Number.parseFloat(money)) ? 'S/'.concat(' ', Number.parseFloat(money).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) : '-'
    },
    getStatus(status) {
      let label = ''
      switch (status) {
        case 'rejected':
          label = 'Rechazado'
          break
        case 'notApproved':
          label = 'Pendiente'
          break
        case 'approved':
          label = 'Aceptado'
          break
        default:
          break
      }
      return label
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card .card-body {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
